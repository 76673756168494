import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["validationButton", "submitButton", "validationType", "validationTagOrUIN", "validationDetails", "breedSelect", "showWeight"]

  connect() {
    // this.element.scrollIntoView()
  }

  validateTagOrUIN() {
    var validationButton = this.validationButtonTarget;
    var submitButton = this.submitButtonTarget;
    var validationType = this.validationTypeTarget;
    var validationTagOrUIN = this.validationTagOrUINTarget;
    var validationDetails = this.validationDetailsTarget;

    fetch('/my_orders/'+ validationType.value + '/' + validationTagOrUIN.value + '/validate')
    .then(res => res.json())
      .then(res => {
        // console.log(res[0]);
        if (!(res.length === 0)) {

          const alertMessage = document.getElementById('check-in-validation-alert');
          alertMessage.innerHTML = "";

          const validationTag = document.getElementById('details-validation-tag');
          validationTag.innerHTML = res[0].uin;

          const validationReg = document.getElementById('details-validation-registration');
          validationReg.innerHTML = res[0].registry_number;

          const validationName = document.getElementById('details-validation-name');
          validationName.innerHTML = res[0].exhibitors;

          const validationCounty = document.getElementById('details-validation-county');
          validationCounty.innerHTML = res[0].county;

          const validationTGBA = document.getElementById('details-validation-tgba');
          validationTGBA.innerHTML = res[0].tlba_flock;

          validationDetails.classList.remove('d-none');
          submitButton.disabled = false;
        } else {
          validationDetails.classList.add('d-none');
          submitButton.disabled = true;

          const alertMessage = document.getElementById('check-in-validation-alert');
          alertMessage.innerHTML = "<div class='alert alert-danger' role='alert' data-controller='check-in'>Validation tag or registration number was not found</div>";


        }
      });
  }

  handleBreedChange(e) {
    const breedSelect = this.breedSelectTarget;
    const showWeight = this.showWeightTarget;

    if (breedSelect.value == 'Dorper') {
      // 00 – 140
      showWeight.setAttribute("max", 140);
      // if (showWeight.value > 140)
      //   showWeight.value = 140;
    } else {
      // 100 – 175
      showWeight.setAttribute("max", 175);
    }
  }
}
